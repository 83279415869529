import * as React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import { Wrapper } from './styled';

type StaticImageProps = React.ComponentProps<typeof StaticImage>;

type BannerProps = {
  render: (
    bannerRenderProps: Pick<StaticImageProps, 'onLoad' | 'onStartLoad'>,
  ) => React.ReactNode;
};

const DELAY = 300;

function Banner({ render }: BannerProps) {
  const [loaded, setLoaded] = React.useState(false);

  const onLoad = React.useCallback(() => {
    setTimeout(() => {
      setLoaded(true);
    }, DELAY);
  }, []);

  const onStartLoad = React.useCallback<
    NonNullable<StaticImageProps['onStartLoad']>
  >(({ wasCached }) => {
    if (wasCached) {
      setTimeout(() => {
        setLoaded(true);
      }, DELAY);
    }
  }, []);

  return (
    <Wrapper data-loaded={loaded || undefined}>
      {render({ onLoad, onStartLoad })}
    </Wrapper>
  );
}

export default Banner;
