import { styled } from '@linaria/react';
import { COLOR, TEXT } from 'theme/vars';

export const Wrapper = styled.div`
  margin: 0 auto 20px;
  display: block;

  .gatsby-image-wrapper img {
    height: auto !important;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  > * {
    margin: 20px;
  }
`;

export const Description = styled.span`
  display: block;
  text-align: center;

  ${TEXT.BODY_SMALL}

  color: var(${COLOR.NEUTRAL_500});
`;
