import * as React from 'react';

import { Wrapper, Content, Description } from './styled';

type ImageProps = {
  children: React.ReactNode;
  description?: string;
} & React.HTMLAttributes<HTMLDivElement>;

function Images({ children, description, ...rest }: ImageProps) {
  return (
    <Wrapper {...rest}>
      <Content>{children}</Content>

      {description && <Description>{description}</Description>}
    </Wrapper>
  );
}

export default Images;
