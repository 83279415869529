import * as React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import { GlobalLayout, WorkLayout } from 'components/Layout';
import { Banner, Images, Header } from 'components/Works';

function Aisva() {
  return (
    <GlobalLayout>
      <WorkLayout>
        <Banner
          render={({ onLoad, onStartLoad }) => (
            <StaticImage
              placeholder='none'
              onStartLoad={onStartLoad}
              onLoad={onLoad}
              alt='Desktop main page'
              src='../../images/aisva.png'
            />
          )}
        />

        <Header title='AIS VA' />

        <p>
          Internal audit is something that requires a lot of paperwork. Usually
          every document has to be created, edited, approved and that involves
          many people. Things get especially difficult when there are links
          between multiple documents, e.g. document numbers, mentions, etc. To
          solve this problem and improve the process of creating the necessary
          documentation the AIS VA system was created.
        </p>
        <h2>The goal</h2>
        <p>
          The system was meant to automate many processes that were previously
          done by hand, for example:
        </p>
        <ul>
          <li>
            Simplify the process of creating/editing documents via templates and
            data catalogs
          </li>
          <li>Keep the information regarding audit and related subjects</li>
          <li>Control the workflow of the audit</li>
          <li>Import & Export data to/from Word or Excel</li>
        </ul>
        <h2>My role</h2>
        <p>
          I was leading the fronted development of this project and helping to
          design the system in a user-friendly and scalable way. I have designed
          the architecture of the project’s SPA, managed the work of other
          developers and written many parts of the application myself.
        </p>

        <Images description='Engagements table'>
          <StaticImage alt='AIS VA' src='../../images/aisva/screen-1.png' />
        </Images>

        <h2>Technologies</h2>
        <p>
          This was the second project I’ve made with React and though it was
          much easier to develop already having some experience, I’ve faced many
          challenges with migrating the codebase from one major version to the
          other, improving the build & runtime performance and also managing the
          state of the application, that consisted of many interlinked entities.
        </p>
        <p>
          Eventually after trial and error these problems were solved and in its
          final shaped the project was relying mainly on frame-components, that
          were used to generate tables, forms and cards based on the json-like
          configuration.
        </p>
        <p>The final frontend stack of the project looked like this:</p>
        <ul>
          <li>React.js</li>
          <li>CSS modules combined with Stylus for styling</li>
          <li>Redux for state management combined with Immutable.js</li>
        </ul>

        <Images description='Findings form'>
          <StaticImage alt='AIS VA' src='../../images/aisva/screen-2.png' />
        </Images>

        <h2>Result</h2>
        <p>
          The project was released in 3 iterations, each consisting of one major
          part. Based on a feedback provided by the client and users the system
          was improved and since then used by the company’s auditors in their
          daily work.
        </p>
      </WorkLayout>
    </GlobalLayout>
  );
}

export default Aisva;
