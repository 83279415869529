import { styled } from '@linaria/react';

export const Wrapper = styled.div`
  width: 740px;
  margin: 0 auto 20px;
  padding: 0 20px;
`;

export const Content = styled.div`
  display: table;
`;

export const Title = styled.h1`
  width: auto !important;
  margin: 0 !important;
  padding: 0 !important;

  display: table;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;

  margin-top: 5px;
`;

export const Link = styled.a``;
