import { IconButton } from 'components/Controls';
import * as React from 'react';
import { External } from 'theme/icons';

import { Wrapper, Content, Title, Info, Link } from './styled';

type HeaderProps = {
  title: string;
  link?: string;
};

function Header({ title, link }: HeaderProps) {
  return (
    <Wrapper>
      <Content>
        <Title>{title}</Title>

        <Info>
          {link && (
            <Link href={link} rel='noopener noreferrer' target='_blank'>
              <IconButton>
                Website
                <External />
              </IconButton>
            </Link>
          )}
        </Info>
      </Content>
    </Wrapper>
  );
}

export default Header;
