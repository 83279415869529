import { styled } from '@linaria/react';
import { SIZE } from 'theme/vars';

export const Wrapper = styled.div`
  @media (min-width: 1024px) {
    height: calc(100vh - var(${SIZE.HEADER_HEIGHT}));
    margin: 0 auto;
    width: 120vh;
    max-width: 1024px;
    padding: 20px 40px 80px;
  }

  @media (max-width: 1023px) {
    padding: 0 40px 20px;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;


  will-change: transform, opacity;
  transition 1s ease;
  transition-property: opacity, transform;

  &:not(&[data-loaded]) {
    opacity: 0;

    transform: translateY(-50px);
  }

  &[data-loaded] {
    opacity: 1;
    transform: translateY(0);
  }
`;
